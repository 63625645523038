import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "support",
      "style": {
        "position": "relative"
      }
    }}>{`Support`}<a parentName="h1" {...{
        "href": "#support",
        "aria-label": "support permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    On this page, you will find different methods of getting in touch with the HDS team.
    </LeadParagraph>
    <h2 {...{
      "id": "contact",
      "style": {
        "position": "relative"
      }
    }}>{`Contact`}<a parentName="h2" {...{
        "href": "#contact",
        "aria-label": "contact permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`If you are making a feature request, please refer to the `}<InternalLink href="/getting-started/contributing/before-contributing" mdxType="InternalLink">{`Contributing`}</InternalLink>{` page. If you are making a bug report, read about reporting issues below.`}</p>
    <h3 {...{
      "id": "email",
      "style": {
        "position": "relative"
      }
    }}>{`Email`}<a parentName="h3" {...{
        "href": "#email",
        "aria-label": "email permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`If you’ve got a question or a suggestion, you can get in touch with the team by email. Our address is `}<a parentName="p" {...{
        "href": "mailto:hds@hel.fi"
      }}>{`hds@hel.fi`}</a>{`. Messages will be answered by the HDS team as soon as possible.`}</p>
    <h3 {...{
      "id": "slack-channels",
      "style": {
        "position": "relative"
      }
    }}>{`Slack channels`}<a parentName="h3" {...{
        "href": "#slack-channels",
        "aria-label": "slack channels permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>HDS team members can also be reached in the <ExternalLink href="https://helsinkicity.slack.com/archives/CHCV3KTHA" mdxType="ExternalLink">#designsystem Slack channel</ExternalLink> in the City of Helsinki Slack.</p>
    <h2 {...{
      "id": "issues-and-features",
      "style": {
        "position": "relative"
      }
    }}>{`Issues and features`}<a parentName="h2" {...{
        "href": "#issues-and-features",
        "aria-label": "issues and features permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "submitting-an-issue",
      "style": {
        "position": "relative"
      }
    }}>{`Submitting an issue`}<a parentName="h3" {...{
        "href": "#submitting-an-issue",
        "aria-label": "submitting an issue permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>You can submit issues and bug reports to <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system" mdxType="ExternalLink">HDS repository in GitHub</ExternalLink>.</p>
    <h3 {...{
      "id": "feature-requests",
      "style": {
        "position": "relative"
      }
    }}>{`Feature requests`}<a parentName="h3" {...{
        "href": "#feature-requests",
        "aria-label": "feature requests permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>You can submit feature requests to <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system" mdxType="ExternalLink">HDS repository in GitHub</ExternalLink>. You can also get in touch with HDS team with any of the above-mentioned methods.</p>
    <h3 {...{
      "id": "contributing",
      "style": {
        "position": "relative"
      }
    }}>{`Contributing`}<a parentName="h3" {...{
        "href": "#contributing",
        "aria-label": "contributing permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`See `}<InternalLink href="/getting-started/contributing/before-contributing" mdxType="InternalLink">{`Contributing.`}</InternalLink></p>
    <h2 {...{
      "id": "hds-v1-documentation",
      "style": {
        "position": "relative"
      }
    }}>{`HDS V1 documentation`}<a parentName="h2" {...{
        "href": "#hds-v1-documentation",
        "aria-label": "hds v1 documentation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>Looking for HDS V1 documentation? Find it here: <ExternalLink href="https://hds.hel.fi/v1" openInNewTab mdxType="ExternalLink">HDS V1 documentation</ExternalLink>.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      